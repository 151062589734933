import React                from "react";
import PropTypes            from "prop-types";
import { Switch, Redirect } from "react-router-dom";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { fetchHeader }      from "Actions/Store/StoreActions";
import { setRedirect }      from "Actions/Content/NotificationActions";

// Sections
import HomePage             from "Components/Content/Home/HomePage";
import CatalogPage          from "Components/Content/CatalogPage";
import AboutUsPage          from "Components/Content/AboutUsPage";
import BenefitsPage         from "Components/Content/Benefits/BenefitsPage";
import NewsList             from "Components/Content/News/NewsList";
import NewsPage             from "Components/Content/News/NewsPage";
import DownloadAppPage      from "Components/Content/DownloadAppPage";
import NotificationPage     from "Components/Content/Notifications/NotificationPage";
import ErrorPage            from "Components/Content/ErrorPage";

import CategoryList         from "Components/Store/Category/CategoryList";
import CategoryPage         from "Components/Store/Category/CategoryPage";
import OfferList            from "Components/Store/Offer/OfferList";
import OfferPage            from "Components/Store/Offer/OfferPage";
import BrandList            from "Components/Store/Brand/BrandList";
import BrandPage            from "Components/Store/Brand/BrandPage";
import CylindersPage        from "Components/Store/Bike/CylindersPage";
import CylinderPage         from "Components/Store/Bike/CylinderPage";
import BikePage             from "Components/Store/Bike/BikePage";
import CartContainer        from "Components/Store/Cart/CartContainer";
import SearchPage           from "Components/Store/Product/SearchPage";
import NewestPage           from "Components/Store/Product/NewestPage";
import FavoritePage         from "Components/Store/Product/FavoritePage";
import HistoryPage          from "Components/Store/Product/HistoryPage";

import TermsUser            from "Components/Terms/TermsUser";
import ClientContainer      from "Components/Client/ClientContainer";

// Components
import Header               from "Components/Core/Header";
import Menu                 from "Components/Core/Menu";
import Navigation           from "Components/Core/Navigation";
import Footer               from "Components/Core/Footer";
import Bar                  from "Components/Core/Bar";
import UserRoute            from "Components/Utils/Route/UserRoute";

// Styles
import "Styles/Components/Core/Container.css";



/**
 * The Container
 */
class Container extends React.Component {
    // The Current State
    state = {
        showMenu : false,
        showNav  : false,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchHeader();
        window.addEventListener("resize", this.handleHeight);
        this.handleHeight();
    }

    /**
     * Redirect if required
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.redirect !== prevProps.redirect && this.props.redirect) {
            this.props.history.push(this.props.redirect);
            this.props.setRedirect("");
        }
    }

    /**
     * Toggles the Menu
     * @param {Boolean} showMenu
     * @returns {Void}
     */
    toggleMenu = (showMenu) => {
        this.setState({ showMenu });
    }

    /**
     * Toggles the Menu
     * @param {Boolean} showNav
     * @returns {Void}
     */
    toggleNav = (showNav) => {
        this.setState({ showNav });
    }

    /**
     * Closes the Navigation
     * @returns {Void}
     */
    closeNav = () => {
        this.setState({ showNav : false });
    }

    /**
     * Handles the App Height
     * @returns {Void}
     */
    handleHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isApp, location   } = this.props;
        const { showMenu, showNav } = this.state;

        return <div className={"app-container" + (isApp ? " app" : "")}>
            <Header path={location.pathname} showNav={showNav} toggleNav={this.toggleNav} />
            <Menu toggleMenu={this.toggleMenu} />
            <Navigation open={showNav} onClose={this.closeNav} />

            <main className={"content-main" + (showMenu ? " content-backdrop" : "")}>
                <Switch>
                    <UserRoute path="/categorias/:category/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                   component={CategoryPage}  exact />
                    <UserRoute path="/categorias/:category/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                              component={CategoryPage}  exact />
                    <UserRoute path="/categorias/:category/:subcategory([a-z][\w-]{2,})"                                                                                component={CategoryPage}  exact />
                    <UserRoute path="/categorias/:category/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                component={CategoryPage}  exact />
                    <UserRoute path="/categorias/:category/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                           component={CategoryPage}  exact />
                    <UserRoute path="/categorias/:category"                                                                                                             component={CategoryPage}  exact />

                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:amount(a\d)/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"  component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:amount(a\d)/:type(t\d)/:price(p\d)/:sort(s\d)"             component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                            component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:amount(a\d)/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                               component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:amount(a\d)/:type(t\d)/:price(p\d)/:sort(s\d)"                                          component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})"                                                                                         component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:amount(a\d)/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                         component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer/:amount(a\d)/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                    component={OfferPage}     exact />
                    <UserRoute path="/ofertas/:offer"                                                                                                                   component={OfferPage}     exact />

                    <UserRoute path="/motos/:bike/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                  component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                             component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                               component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                               component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                                          component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:category([a-z][\w-]{2,})"                                                                                            component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                         component={BikePage}      exact />
                    <UserRoute path="/motos/:bike/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                                    component={BikePage}      exact />
                    <UserRoute path="/motos/:bike"                                                                                                                      component={BikePage}      exact />
                    
                    <UserRoute path="/cilindradas/:cylinder/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"        component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                   component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                     component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                     component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                                component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:category([a-z][\w-]{2,})"                                                                                  component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                               component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                          component={CylinderPage}  exact />
                    <UserRoute path="/cilindradas/:cylinder"                                                                                                            component={CylinderPage}  exact />
                    
                    <UserRoute path="/marcas/:type(nacionales|importadas)"                                                                                              component={BrandList}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:price(p\d)/:sort(s\d)/:page(\d+)"                           component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:price(p\d)/:sort(s\d)"                                      component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                             component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:price(p\d)/:sort(s\d)/:page(\d+)"                                                        component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:price(p\d)/:sort(s\d)"                                                                   component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})"                                                                                          component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                                  component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand/:price(p\d)/:sort(s\d)"                                                                                             component={BrandPage}     exact />
                    <UserRoute path="/marcas/:brand"                                                                                                                    component={BrandPage}     exact />
                    
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"               component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                          component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                            component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                            component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                                       component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})"                                                                                         component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                      component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                                 component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search"                                                                                                                   component={SearchPage}    exact />
                    
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                       component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                  component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                                    component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                    component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                                               component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})"                                                                                                 component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                              component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                                         component={NewestPage}    exact />
                    <UserRoute path="/nuevos"                                                                                                                           component={NewestPage}    exact />
                    
                    <UserRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                    component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                               component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                                 component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                 component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                                            component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:category([a-z][\w-]{2,})"                                                                                              component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                           component={FavoritePage}  exact />
                    <UserRoute path="/favoritos/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                                      component={FavoritePage}  exact />
                    <UserRoute path="/favoritos"                                                                                                                        component={FavoritePage}  exact />
                    
                    <UserRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                    component={HistoryPage}   exact />
                    <UserRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                               component={HistoryPage}   exact />
                    <UserRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                                 component={HistoryPage}   exact />
                    <UserRoute path="/historial/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                 component={HistoryPage}   exact />
                    <UserRoute path="/historial/:category([a-z][\w-]{2,})/:type(t\d)/:price(p\d)/:sort(s\d)"                                                            component={HistoryPage}   exact />
                    <UserRoute path="/historial/:category([a-z][\w-]{2,})"                                                                                              component={HistoryPage}   exact />
                    <UserRoute path="/historial/:type(t\d)/:price(p\d)/:sort(s\d)/:page(\d+)"                                                                           component={HistoryPage}   exact />
                    <UserRoute path="/historial/:type(t\d)/:price(p\d)/:sort(s\d)"                                                                                      component={HistoryPage}   exact />
                    <UserRoute path="/historial"                                                                                                                        component={HistoryPage}   exact />

                    <UserRoute path="/categorias"             component={CategoryList}     exact />
                    <UserRoute path="/ofertas"                component={OfferList}        exact />
                    <UserRoute path="/cilindradas"            component={CylindersPage}    exact />
                    <UserRoute path="/marcas"                 component={BrandList}        exact />
                    <UserRoute path="/compras"                component={CartContainer}    exact />

                    <UserRoute path="/catalogo"               component={CatalogPage}      exact />
                    <UserRoute path="/quienes-somos"          component={AboutUsPage}      exact />
                    <UserRoute path="/beneficios"             component={BenefitsPage}     exact />
                    <UserRoute path="/terminos-y-condiciones" component={TermsUser}        exact />
                    <UserRoute path="/novedades/:page"        component={NewsList}         exact />
                    <UserRoute path="/novedades"              component={NewsList}         exact />
                    <UserRoute path="/novedad/:news"          component={NewsPage}         exact />
                    <UserRoute path="/notificaciones"         component={NotificationPage} exact />
                    <UserRoute path="/app"                    component={DownloadAppPage}  exact />
                    <UserRoute path="/cuenta"                 component={ClientContainer}        />

                    <UserRoute path="/404"                    component={ErrorPage}        exact />
                    <UserRoute path="/"                       component={HomePage}         exact />

                    <Redirect from="*" to="/" />
                </Switch>
            </main>
            {isApp ? <Bar path={location.pathname} /> : <Footer />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history     : PropTypes.object.isRequired,
        fetchHeader : PropTypes.func.isRequired,
        setRedirect : PropTypes.func.isRequired,
        isApp       : PropTypes.bool.isRequired,
        redirect    : PropTypes.string.isRequired,
        location    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp    : state.core.isApp,
            redirect : state.notification.redirect,
        };
    }
}

export default withRouter(connect(Container.mapStateToProps, {
    fetchHeader, setRedirect,
})(Container));
