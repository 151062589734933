import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";
import Title                from "Components/Utils/Common/Title";

// Styles
import "Styles/Components/Content/Catalog.css";

// Constants
const WINDOW_WIDTH = 800;



/**
 * The Catalog Page
 */
class CatalogPage extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const backUrl    = this.props.isApp ? "/cuenta" : "";
        const pageLayout = window.innerWidth < WINDOW_WIDTH ? "&pageLayout=singlePage" : "";
        const catalog    = "revista";

        return <Wrapper className="catalog-container" withSpacing>
            <Title message="CATALOG_TITLE" href={backUrl} />
            <Card className="catalog-content">
                <iframe
                    title={NLS.get("CATALOG_TITLE")}
                    src={`https://e.issuu.com/embed.html?backgroundColor=%232e333e&hideIssuuLogo=true&u=tercom4&d=${catalog}${pageLayout}`}
                    allow="fullscreen"
                    allowFullScreen
                />
            </Card>
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isApp : PropTypes.bool.isRequired,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp : state.core.isApp,
        };
    }
}

export default connect(CatalogPage.mapStateToProps)(CatalogPage);
